<template>
	<section :class="['fluid-layout', { 'no-nav': $route.meta.hideNav }]">
		<StsHero :image="image" :logo="logo" :logoTitle="name" :overflow="overflow">
			<PortalTarget name="hero" />
		</StsHero>

		<main :class="{ 'pad-top': overflow }">
			<section class="content-container">
				<router-view :applicant="applicant" />
			</section>
			<footer class="fluid-footer">
				<StsDisclaimer
					v-if="!applicant.activeApplicationIsDeclined"
					:productTypeId="applicant.activeApplicationProductTypeID"
				/>
			</footer>
		</main>
	</section>
</template>

<script>
	import { PortalTarget } from 'portal-vue';
	import { mapGetters, mapState } from 'vuex';

	import { StsDisclaimer, StsHero } from '@/components/SwitchThink';
	import { NCUABold as NCUALogo } from '@/components/SwitchThink/logos';

	export default {
		name: 'FluidLayout',
		components: {
			PortalTarget,
			NCUALogo,
			StsHero,
			StsDisclaimer,
			EHOLogo: () => import('@/components/SwitchThink/logos').then(({ EHOLogo }) => EHOLogo)
		},
		props: {
			overflow: {
				type: Boolean,
				required: false
			}
		},
		computed: {
			...mapState('branding', {
				logo: state => state.images.logo,
				name: state => state.name
			}),
			...mapState('pageOptions', { image: state => state.currentHeader }),
			...mapState('applicant', { applicant: state => state }),
			...mapGetters('applicant', ['activeApplication'])
		}
	};
</script>

<style lang="scss" scoped>
	.fluid-layout {
		background-color: var(--background);
		position: relative;
		min-height: 100vh;
		padding-top: 50px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		&.no-nav {
			padding-top: 0;
		}

		main {
			flex: 1;
			box-sizing: border-box;
			position: relative;
			width: 100%;
			margin: 0 auto;
			padding-top: 1.5rem;

			&.pad-top {
				padding-top: 8.5rem;
			}
		}

		.content-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		footer {
			bottom: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 auto;
			padding-top: 0;
			margin-top: 1.5rem;

			&.heloc-footer svg {
				color: var(--primary);
			}

			&.fluid-footer {
				svg {
					max-width: 20rem;
					width: 100%;
					height: auto;
					fill: var(--primary);
				}

				a {
					display: inline-block;
					padding: 1.5rem;
					font-size: 1.5rem;
					color: var(--primary);
					text-decoration: none;
					text-align: center;
					font-weight: 300;

					&:after {
						display: block;
						content: '';
						border-bottom: solid 1px var(--primary);
						transform: scaleX(0);
						transition: transform 250ms ease-in-out;
					}

					&:hover:after {
						transform: scaleX(1);
					}
				}
			}
		}
	}

	@media only screen and (min-width: 850px) {
		.fluid-layout {
			header {
				position: fixed;
				width: 35vw;
			}

			.content-container {
				padding-bottom: 10rem;
			}

			main {
				display: flex;
				flex-direction: column;
				margin: 0;
				margin-left: 35vw;
				width: auto;
				padding-top: 0;

				&.pad-top {
					padding-top: 0;
				}

				footer {
					padding: 0;

					a {
						padding-bottom: 0;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1525px) {
		.fluid-layout main .content-container {
			width: 100%;
			margin: 0 auto;
		}
	}

	@media only screen and (min-width: 1920px) {
		.fluid-layout main .content-container {
			max-width: 100%;
		}
	}
</style>
